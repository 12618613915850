@import "../../../styles/settings/breakpoints.scss";
@import "../../../styles/settings/variable.scss";

.home-page {
	width: 100%;
	padding: $space-15;
}

.list-children {
	width: fit-content;
	height: 400px;
	overflow-y: auto;
	border: 1px solid $color-border;
	padding: $space-10;
	padding-right: 10px;
}
.children {
	width: 300px;
}
