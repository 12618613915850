@import "../../../styles/settings/breakpoints.scss";
@import "../../../styles/settings/variable.scss";
@import "../../../styles/settings/mixin.scss";

.children-profile {

  &__text {
    color: $color-blue;
    font-size: 24px;
  }

  &__span {
    color: #474747;
    font-size: 16px;
  }

}
